// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

import NajaService from './services/Naja'
NajaService.initialize()

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import ScrollService from './services/Scroll'
ScrollService.useNative()

import { loadComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
// import lazySizes from "lazysizes"
// lazySizes.cfg.lazyClass = "is-lazy"
// lazySizes.cfg.loadingClass = "is-lazy-loading"
// lazySizes.cfg.loadedClass = "is-lazy-loaded"
// document.addEventListener("lazybeforeunveil", function (e) {
//     var bg = e.target.getAttribute("data-bg")
//     if (bg) {
//         e.target.style.backgroundImage = "url(" + bg + ")"
//     }
// })

// const animateLazyImages = () => {
//     const imageEls = [...document.querySelectorAll('img[loading="lazy"]:not(.is-loading):not(.is-loaded)')]
//     imageEls.forEach(imageEl => {
//         const reveal = () => {
//             clearTimeout(antiTimer)
//             imageEl.classList.remove('is-loading')
//             imageEl.classList.add('is-loaded')
//         }

//         const antiTimer = setTimeout(() => {
//             reveal()
//         }, 3000)

//         if (imageEl.complete) {
//             reveal()
//         } else {
//             imageEl.classList.add('is-loading')
//             imageEl.addEventListener('load', reveal)
//         }

//     })
// }

// import debounce from 'lodash/debounce'
// const debouncedAnimateLazyImages = debounce(animateLazyImages, 100)

// const observer = new MutationObserver(() => {
//     debouncedAnimateLazyImages()
// });
// observer.observe(document.documentElement || document.body, { attributes: false, childList: true, subtree: true, characterData: false });